<template>
  <div class="container">
    <div class="banner">
      <img
        class="banner__img"
        src="@/assets/images/banner-logo.png"
        alt="Banner logo"
      />
      <p class="banner__text">Invision360 Annual Review Tool</p>
    </div>
    <div class="main">
      <h1 class="title">Reset your password</h1>
      <p class="subtitle" v-if="uiState !== 'FORM_SUBMITTED'">
        Enter a new password below
      </p>
      <div class="field-container">
        <form @submit.prevent="checkForm" method="post">
          <div v-if="uiState !== 'FORM_SUBMITTED'">
            <TextField
              v-model.lazy="$v.formResponses.password.$model"
              label="Password"
              icon="far fa-user"
              type="password"
            />
            <TextField
              v-model.lazy="$v.formResponses.repeat.$model"
              label="Confirm Password"
              icon="far fa-user"
              type="password"
            />
          </div>
          <div v-if="empty && uiState === 'SUBMIT_CLICKED'">
            <p class="is-danger" v-if="!$v.formResponses.password.required">
              Password is required.
            </p>
          </div>
          <div v-else-if="errors">
            <p
              class="is-danger"
              v-if="!$v.formResponses.password.strongPassword"
            >
              Passwords must be at least 12 characters and a mix of numbers,
              uppercase and lowercase letters, and symbols.
            </p>
            <p
              class="is-danger"
              v-if="
                $v.formResponses.password.strongPassword &&
                !$v.formResponses.password.sameAsPassword
              "
            >
              The passwords do not match.
            </p>
          </div>
          <div class="field">
            <button
              class="button"
              type="submit"
              v-if="uiState !== 'FORM_SUBMITTED'"
            >
              Reset
            </button>
            <div v-if="uiState === 'FORM_SUBMITTED'">
              <p class="subtitle">Password successfully changed!</p>
              <button class="button is-info" @click.prevent="navigateToLogin">
                Return to Invision Login
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import TextField from '@/components/common/TextField'
import { validationMixin } from 'vuelidate'
import { required, sameAs } from 'vuelidate/lib/validators'

export default {
  name: 'ResetPassword',
  mixins: [validationMixin],
  components: { TextField },
  props: {
    token: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      errors: false,
      empty: true,
      uiState: 'SUBMIT_NOT_CLICKED',
      submitStatus: null,
      formResponses: {
        password: '',
        repeat: '',
      }
    }
  },
  validations: {
    formResponses: {
      password: {
        required,
        strongPassword (password) {
          return (
            /[a-z]/.test(password) &&
            /[0-9]/.test(password) &&
            /\W|_/.test(password) &&
            password.length >= 12
          )
        }
      },
      repeat: {
        sameAsPassword: sameAs('password')
      }
    }
  },
  methods: {
    async checkForm () {
      this.empty = !this.$v.formResponses.$anyDirty
      this.errors = this.$v.formResponses.$invalid
      this.uiState = 'SUBMIT_CLICKED'
      if (this.errors === false && this.empty === false) {
        try {
          await this.ApiService.resetPassword(this.formResponses.password, this.token)
          this.uiState = 'FORM_SUBMITTED'
        } catch (error) {
          this.uiState = 'SUBMIT_ERROR'
          if (error.response.status === 401) {
            alert(`Your password reset has exceeded the time allowed (1 hour). Please re-visit the forgotten password link and submit your email again.`)
          }
        }
      }
    },
    navigateToLogin () {
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";

.container {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: 100vw;
  height: 100vh;
  .banner {
    position: relative;
    width: 100%;
    height: 3.5rem;
    top: 0;
    // background: linear-gradient(90deg, #ec685d 0.56%, #ea5296 97.23%);
    background: $brand-gradient;
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    grid-template-columns: repeat(2, max-content) 1fr;
    &__img {
      position: relative;
      padding-left: $padding1x;
      height: auto;
      width: 54px;
    }
    &__text {
      position: relative;
      padding-left: $padding1x;
      font-weight: 600;
      font-size: $font1x;
      color: white;
    }
  }
  .main {
    position: relative;
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    .title {
      font-size: 1.2rem;
      font-weight: 600;
    }
    .field-container {
      width: 350px;
      margin-top: 2.5rem;
      .field {
        button {
          margin-top: 1rem;
          width: 100%;
        }
      }
      .is-danger {
        color: red;
      }
      .is-info {
        background: #007bff;
      }
      p.subtitle {
        text-align: center;
        font-weight: bold;
      }
    }
  }
}
</style>
