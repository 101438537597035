<template>
  <div class="requestReset">
    <RequestResetForm @request-reset="processPasswordResetRequest" @clear-error="clearError" :error="error" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import RequestResetForm from '@/components/forms/RequestPasswordReset.vue'

export default {
  name: 'RequestReset',
  components: { RequestResetForm },
  data () {
    return {
      error: null,
      count: 0
    }
  },
  computed: {
    ...mapGetters({ user: 'user/user' })
  },
  methods: {
    ...mapActions({ requestPasswordReset: 'user/requestPasswordReset' }),
    async processPasswordResetRequest (payload) {
      const { email } = payload
      this.count++
      if (!email) {
        this.error = 'Please make sure an email address has been entered.'
        return
      }
      try {
        await this.requestPasswordReset({ email })
      } catch (err) {
        this.error = 'There was an error processing your request. Please contact your super user.'
      }
    },
    clearError () {
      this.error = null
    }
  },
  mounted () {

  },
  beforeDestroy () {
    this.$off('request-reset', this.processPasswordResetRequest)
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
.requestReset {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $brand-gradient;
}
</style>
